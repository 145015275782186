import React from 'react'
import Nav2 from "../../components/Navbar/Nav2";

export default function PrivacyPolicy() {
    return (
        <div >
             <Nav2 />

             Privacy Policy for Tecohome  <br></br><br></br>

            Effective Date: 27th Sep 2023 <br></br> <br></br>

            Teconico Private Limited ("we," "our," or "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our skill, Tecohome ("the Skill"). By using the Skill, you consent to the practices described in this Privacy Policy.<br></br><br></br>

             1. Information We Collect <br></br>

            1.1.  Account Linking Information:  When you link your account with the Skill, we may collect your full name from your profile.<br></br><br></br>

             2. How We Use Your Information <br></br>

            We use the collected information for the following purposes:<br></br>

            2.1.  Smart Home Control:  We use your full name to personalize your experience and control smart home devices that you have connected to your device.<br></br><br></br>

             3. Disclosure of Your Information <br></br>

            We may share your information in the following circumstances:<br></br>

            3.1.  Service Providers:  We may share your information with third-party service providers who assist us in providing and maintaining the Skill. These service providers are bound by confidentiality agreements and are not permitted to use your information for any purpose other than to provide services to us.<br></br>

            3.2.  Legal Compliance:  We may disclose your information if required by law, regulation, or legal process.<br></br><br></br>

             4. Data Security <br></br>

            We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.<br></br><br></br>

             5. Your Choices <br></br>

            5.1.  Account Deletion:  You can unlink your account from the Skill at any time through your settings. If you do so, we will no longer have access to your full name.<br></br><br></br>

             6. Children's Privacy <br></br>

            The Skill is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected information from a child under 13, please contact us, and we will take appropriate steps to delete such information.<br></br><br></br>

             7. Changes to this Privacy Policy <br></br>

            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will provide notice of any material changes by posting the updated Privacy Policy on our website or through the Skill.<br></br><br></br>

             8. Contact Us <br></br>

            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at algodivepvtltd@gmail.com.<br></br>

            By using the Skill, you agree to the terms and conditions of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use the Skill.<br></br><br></br>

            Thank you for using Tecohome!<br></br>


        </div>
    )
}
