import React from 'react'
import Nav2 from "../../components/Navbar/Nav2";
import { Link } from 'react-router-dom';

export default function TermsOfUse() {
    return (
        <div >
            <Nav2 />

            <header>
                <h3>Terms of Use for Tecohome Skill - Smart Home Control</h3>
            </header>
            <br></br>
            <main>
                <section>
                    <h4>1. Account Linking and Personal Information</h4>
                    <p>
                        To use the Skill, you may be required to link your account with your Tecohome account. During this account linking process, we may collect and store your full name as provided by your account. We may use this information to personalize your experience and to control smart home devices as per your instructions.
                    </p>
                </section>
                <section>
                    < h4>2. Privacy</ h4>
                    <p>
                        We respect your privacy and are committed to protecting your personal information. Our use of your full name is governed by our Privacy Policy, which can be found <Link to='https://teconico.in/privacypolicy'>here</Link>. By using the Skill, you consent to the collection and use of your personal information as described in our Privacy Policy.
                    </p>
                </section>
                <section>
                    < h4>3. Smart Home Control</ h4>
                    <p>
                        The primary function of the Skill is to enable you to control smart home devices using voice commands. By using the Skill, you acknowledge that you may be able to perform actions such as turning lights on/off, adjusting thermostats, locking/unlocking doors, and other similar actions. You are responsible for the security and safety of your smart home devices and should use the Skill with due diligence.
                    </p>
                </section>
            </main>
            <footer>
                <p>
                    If you have any questions or concerns about these Terms or the Skill, please contact us at algodivepvtltd@gmail.com.
                </p>
            </footer>



        </div>
    )
}
