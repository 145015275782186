import React from 'react'
import Nav2 from "../../components/Navbar/Nav2";

export default function PrivacyPolicyForApp() {
    return (
        <div >
            <Nav2 />

            Privacy Policy for Tecohome App  <br></br><br></br>

            Effective Date: 12th Dec 2023 <br></br> <br></br>

            Teconico Private Limited ("we," "our," or "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our app, Tecohome. By using the app, You agree to storage and usage of your information as described by us in accordance with the privacy policy.<br></br><br></br>

            1. Information We Collect <br></br>

            1.1.  Email Id:  We collect your email id for account management and communication purposes.<br></br>
            1.2.  Name and Profile Picture:  We collect your name and profile picture to personalize user experience.<br></br><br></br>

            2. How We Use Your Information <br></br>

            The sole purpose of collecting your information is for the following:- <br></br>

            App enhancement: We use the collected data for implementing new features, understanding users and identifying patterns. Our main goal throughout all this would be to enrich user experience. <br></br>
            Account Management: Your email, name and picture would be used for authentication, management and communication purposes and to provide a personalized experience. .<br></br><br></br>

            3. Disclosure of Your Information <br></br>

            We may share your information in the following circumstances:<br></br>

            3.1.  Service Providers:  We may share your information with third-party service providers who assist us in providing and maintaining the app. These service providers are bound by confidentiality agreements and are not permitted to use your information for any purpose other than to provide services to us.<br></br>

            3.2.  Legal Compliance:  We may disclose your information if required by law, regulation, or legal process.<br></br><br></br>

            4. Data Security <br></br>

            We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.<br></br><br></br>

            5. Your Choices <br></br>

            5.1.  Account Deletion:  You can unlink your account from the Skill at any time through your settings. If you do so, we will no longer have access to your full name.<br></br><br></br>

            6. Events <br></br>

            We collect user’s events and activities including but not limited to Device interactions and App usage patterns <br></br><br></br>

            7. Changes to this Privacy Policy <br></br>

            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will provide notice of any material changes by posting the updated Privacy Policy on our website or through the Skill.<br></br><br></br>

            8. Contact Us <br></br>

            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at developer@teconico.in.<br></br>

            By using the app, you agree to the terms and conditions of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use the app.<br></br><br></br>

            Thank you for using Tecohome!<br></br>


        </div>
    )
}
